import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { useQueryClient } from "@tanstack/react-query";
import { analytics } from "../../../config/FirebaseConfiguration";
import LayoutContainer from "../../../containers/Layout";
import { generateNumber, ReversedOrNot } from "../../../utils/generateNumber";
import Button from "../../../components/Button";
import PersonalCardInfo from "../../../containers/CardInfoContainer/PersonalCardInfo";
import styles from "./styles.css";
import CardsData from "../../../provider/CardsData";
import CustomLink from "../../../components/Links";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import MetaTags from "../../../components/MetaTags";
import MyModal from "../../../components/Modal";
import TarotCard from "../../../components/TarotCard/TarotCard";
import DiaryTarot from "../../../images/DiaryTarot.png";

export default function OneCardLayout({ children }) {
  /**
   * States related to cards
   */
  const [cardLoaded, setCardLoaded] = useState(false);
  const [beforeCardLoaded, setBeforeCardLoaded] = useState(true);
  const [chosenCard, setChosenCard] = useState({});
  const [isReversed, setIsReversed] = useState(false);

  const { data: cards } = CardsData();

  /**
   * Modal
   */
  let [isOpen, setIsOpen] = useState(false);
  let [isNotLiked, setIsNotLiked] = useState(false);

  function closeModal(response) {
    setIsNotLiked(response === "click_not_liked");
    setIsOpen(response === "click_not_liked");
    logEvent(analytics, response, "button");
  }
  /**
   * Client Calls
   */
  const queryClient = useQueryClient();
  const userQuestion = queryClient.getQueryData(["userQuestion"]);
  const userData = useAuthContext();

  const navigate = useNavigate();

  function loadCardData() {
    const randomCard = generateNumber();
    let cardChosen = cards[randomCard];
    setChosenCard(() => cardChosen);
  }

  function cardClicked() {
    setBeforeCardLoaded(false);
    setCardLoaded(true);
    loadCardData();
    setIsReversed(ReversedOrNot()); //50% probability of getting true

    logEvent(analytics, "click_one_card", "button");
  }

  async function saveCard() {
    let userQuestion = queryClient.getQueryData(["userQuestion"]);
    userQuestion.cards.push(chosenCard.name);
    userQuestion.cardsImg.push(chosenCard.img);
    userQuestion.suits.push(chosenCard.suit);
    userQuestion.isReversed.push(isReversed);
    userQuestion.numberOfCards = userQuestion.numberOfCards + 1;
    queryClient.setQueryData(["userQuestion"], userQuestion);
    logEvent(analytics, "click_save_button", "button");
    if (!userData) {
      navigate("/login");
    } else {
      navigate("/saved-card");
    }
  }
  /**
   * Shuffling
   */
  const [isShuffling, setIsShuffling] = useState(false);

  const shuffleCard = () => {
    logEvent(analytics, "click_shuffle", "button");
    setIsShuffling(true);
    setTimeout(() => {
      setIsShuffling(false);
    }, 1000); // Adjust the duration to match your animation
  };

  return (
    <LayoutContainer>
      <h1>Get Advice From Diary Tarot</h1>

      {beforeCardLoaded && (
        <div className="one-card-spread-guide">
          {!userQuestion && (
            <div className="one-card-spread-guide">
              {" "}
              <img className="card-img" src={DiaryTarot} alt="Diary Tarot" />
              <p className="question">
                <CustomLink to="/my-questions">Type a question</CustomLink>
                <CustomLink to="/choose-subject-to-ask-tarot">
                  Choose a question
                </CustomLink>
              </p>
            </div>
          )}
          {userQuestion && (
            <p className="asked_question" css={styles}>
              {" "}
              "{userQuestion.question}"
            </p>
          )}{" "}
          {userQuestion && (
            <div className="guide-to-click">
              <div className="guide-to-click-the-card">
                Focus your attention on your question and vividly picture the
                situation or the person you're inquiring about.
              </div>
              <Button onClick={shuffleCard}>Shuffle</Button>
              <div>Choose one of the cards below</div>
              <div className="cards-shuffling-container">
                <TarotCard
                  cardIndex="1"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="2"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="3"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="4"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="5"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="6"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {userQuestion && !cardLoaded && (
        <div>
          Choose:
          <CustomLink to="/my-questions">Type a new question</CustomLink>
          or
          <CustomLink to="/choose-subject-to-ask-tarot">
            Choose a new question
          </CustomLink>
        </div>
      )}
      {userQuestion && cardLoaded && (
        <div className="guide">
          <p className="asked_question"> "{userQuestion.question}"</p>
        </div>
      )}

      {cardLoaded && userQuestion && (
        <PersonalCardInfo
          chosenCard={chosenCard}
          personalMeaning={0}
          isReversed={isReversed}
          userQuestion={userQuestion.question}
        />
      )}

      {
        <MyModal
          isOpen={isOpen}
          closeModal={closeModal}
          isNotLiked={isNotLiked}
          saveCard={saveCard}
        />
      }
      {cardLoaded && (
        <div className="one-card-spread-guide">
          <p className="donation-message"> Providing AI answers for "Diary Tarot" incurs ongoing costs.
            I developed this site for your benefit and currently offer it free
            of charge, relying on the generosity of users like you to keep it
            running.
          </p>
          <a
            style={{ padding: "0.5rem"}}
            className="button donate-main"
            href="https://ko-fi.com/R5R7P2LKO"
            target="_blank"
            rel="noreferrer"
          >
            Donate 1$
          </a>
          You can find more meaning in your interpretation by referring to the
          master in Tarot reading.By clicking on more meaning you are going to
          be redirected to the website that provides service of professionals.
          <a
            className="button donate"
            href="https://www.mysticsense.com/chat-psychics/#a_aid=diarytarotworld&a_bid=206c45d4"
          >
            {" "}
            More Meaning 🙏
          </a>
          <p>You can save your answer and read it later.</p>
          <Button onClick={saveCard} className="button donate">
            Save Answer
          </Button>
        </div>
      )}
      <MetaTags
        description={
          "Get personalized Tarot insights with AI ChatGPT. Ask any question, and receive expert Tarot interpretations instantly. Uncover answers to life's mysteries through the power of Tarot and AI."
        }
      />
    </LayoutContainer>
  );
}
