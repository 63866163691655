import ArticleContainer from "./article-container";
import articleData from "./articles-data.json";

export default function HowTarotWork() {
  const article = articleData["article1"] || {
    h1: "No title",
    data: "No content available",
  }; 
  const { h1, data} = article;  

  console.log(article);
  return <ArticleContainer title={h1} body={data} />;
}
