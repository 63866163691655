import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import OneCardLayout from "../pages/Reading/OneCardLayout";
import ThreeCardsLayout from "../pages/Reading/ThreeCardsLayout";
import LearnCards from "../pages/Learning/Learn";
import SearchCards from "../pages/SearchCards";
import CardPage from "../pages/Learning/Learn/CardPage";
import QuizzesPage from "../pages/Learning/Quizzes";
import MyQuestions from "../pages/Questions/AskQuestion";
import Login from "../pages/Login";
import MyDiary from "../pages/MyDiary";
import PrivateRoutes from "./PrivateRoutes";
import SavedCard from "../pages/MyDiary/SavedCard";
import ChooseQuestions from "../pages/Questions/ChooseQuestion";
import ChooseCategory from "../pages/Questions/ChooseCategory";
import NotFound from "../pages/PageNotFound";
import AnswerCards from "../pages/MyDiary/AnswerCards";
import ForgotPassword from "../pages/Login/ForgotPassword";
import Registered from "../pages/Login/Registered";
import SupportMe from "../pages/SupportMe";
import AboutMe from "../pages/AboutMe";
import Statistics from "../pages/MyDiary/Statistics";
import AllAskedQuestions from "../pages/MyDiary/AllAskedQuestions";
import ReviewForm from "../pages/Review";
import SevenCardsLayout from "../pages/Reading/SevenCardsLayout";
import Articles from "../pages/Articles";
import YesNoLayout from "../pages/Reading/YesNoLayout";
import YearLayout from "../pages/Reading/YearLayout";
import HowTarotWork from "../pages/Articles/how-tarot-work";
import CanAIReplaceHuman from "../pages/Articles/ai-replace-human";

export default function RouterPath() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route index element={<Home />} />
        <Route path="/my-questions" element={<MyQuestions />} />
        <Route
          path="/choose-subject-to-ask-tarot"
          element={<ChooseCategory />}
        />
        <Route
          path="/choose-subject-to-ask-tarot/:categoryName"
          element={<ChooseQuestions />}
        />
        <Route path="/free-question-tarot" element={<OneCardLayout />} />
        <Route
          path="/past-present-future-tarot"
          element={<ThreeCardsLayout />}
        />
        <Route
          path="/seven-tarot-cards-layout"
          element={<SevenCardsLayout />}
        />
        <Route path="/yes-no-tarot" element={<YesNoLayout />} />
        <Route path="/tarot-year-layout" element={<YearLayout />} />
        <Route path="/tarot-meaning" element={<SearchCards />} />
        <Route path="/learn-tarot-meaning" element={<LearnCards />} />
        <Route path="/learn-tarot-meaning/:cardName" element={<CardPage />} />
        <Route path="/learn-tarot-meaning/quizzes" element={<QuizzesPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registered" element={<Registered />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/support-me" element={<SupportMe />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/review" element={<ReviewForm />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/how-tarot-works" element={<HowTarotWork />} />
        <Route path="/ai-replace-human" element={<CanAIReplaceHuman />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/my-tarot-diary" element={<MyDiary />} />
          <Route path="/saved-card" element={<SavedCard />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/all-asked-questions" element={<AllAskedQuestions />} />
          <Route
            path="/all-asked-questions/:dateTime"
            element={<AnswerCards />}
          />
          <Route path="/my-tarot-diary/:dateTime" element={<AnswerCards />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
