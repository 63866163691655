import LayoutContainer from "../../containers/Layout";
import React from "react";

export default function SupportMe() {
  return (
    <LayoutContainer>
      <div className="autobiography">
        <h1>Back Contact</h1>

        <p>
          If you've found the website readings helpful and would like to support
          the continuation of this journey, you can do so through Ko-fi, a
          platform that allows you to make voluntary contributions.
        </p>

        <h2>Why Support Matters</h2>
        <p>
          Your support not only helps me continue offering free Tarot readings
          but also allows us to engage in the powerful concept of energy
          exchange. In Tarot and many spiritual practices, it's believed that
          when you receive something of value, it's essential to give back in
          some way to maintain the flow of positive energy. By supporting my
          work, you're not only expressing gratitude but also ensuring the
          energy balance in your life.
        </p>

        <h2>How to Support</h2>
        <ol>
          <li>Click the "Buy Me a Coffee" button.</li>
          <a
            href="https://ko-fi.com/R5R7P2LKO"
            target="_blank"
            rel="noreferrer"
          >
            <img
              height="36"
              style={{ border: "0px", height: "36px" }}
              src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
              border="0"
              alt="Buy Me a Coffee at ko-fi.com"
            />
          </a>
          <li>
            Choose the amount of your contribution. Every donation, big or
            small, is deeply appreciated.
          </li>
          <li>
            Leave a message or note if you'd like to share your thoughts, ask
            questions, or request specific readings.
          </li>
        </ol>

        <h2>The Energy Flow</h2>
        <p>
          Remember that energy flows in a circular and reciprocal manner. If
          you've received valuable insights, guidance, or support from the Diary
          Tarot readings, consider giving back to keep the energy flowing
          harmoniously. When you support me, you're not only supporting the
          continuation of this website but also nurturing the positive energy in
          your life.
        </p>

        <h2>Closing Thoughts</h2>
        <p>
          I'm grateful for each and every contribution and the energy exchange
          it represents. Your support allows me to continue providing you with
          meaningful Tarot readings and insights. Thank you for being a part of
          this journey and for contributing to the energy flow that connects us
          all.
        </p>
      </div>
    </LayoutContainer>
  );
}
