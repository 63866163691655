import firebaseApp from "../config/FirebaseConfiguration.js";
import { getFunctions, httpsCallable } from "firebase/functions";

// Set up functions with the correct region
const functions = getFunctions(firebaseApp, "us-central1");

// Call the function
export const generateChatCompletion = httpsCallable(
  functions,
  "generateChatCompletionGPTMiniCore"
);
