import { useQuery } from "@tanstack/react-query";
import { generateChatCompletion } from "../server/servercalls.js";


export async function getChatAPIResponse2(card, question, isReversed) {
  const requestData = {
    card: card,
    question: question,
    isReversed: isReversed,
  };
  try {
    const response = await generateChatCompletion(requestData);
    
    if (response.error) {
      throw new Error(response.error);
    }

    return response.data;
  } catch (error) {
    console.error("API Error:", error.message);
    return { error: error.message };
  }
}

export function useGetChatGPTResponse(card, question, isReversed) {
  return useQuery({
    queryKey: ["ChatGPTResponse"],
    queryFn: () => getChatAPIResponse2(card, question, isReversed),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: (error) => {
      console.error("Query Error:", error); // Handle error globally
    },
  });
}
