import { useState, useEffect } from "react";
import LayoutContainer from "../../containers/Layout";
import { generateHTMLFromText } from "../../utils/formatText";
export default function ArticleContainer({ title, body }) {
  const [articleData, setArticleData] = useState("⏳ Wait for the data");

  useEffect(() => {
    let formattedResponse = generateHTMLFromText(body);
    setArticleData(formattedResponse);
  }, [body]);

  return (
    <LayoutContainer>
    <section>
      <h1>{title}</h1>
      <div
        style={{ margin: "5px" }}
        dangerouslySetInnerHTML={{ __html: articleData }}
      />
    </section>
    </LayoutContainer>
  );
}
