import React, { useState } from "react";
import LayoutContainer from "../../containers/Layout";
import { useCreateReviews } from "../../provider/Reviews";
import ButtonGeneral from "../../components/Button";
import { useNavigate } from "react-router-dom";

const ReviewForm = () => {
  const [review, setReview] = useState("");
  const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);
  const saveReview = useCreateReviews();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setReview(event.target.value);
  };

  const handleSubmit = (event) => {
    const currentTimestampMilliseconds = Date.now();
    event.preventDefault();
    let userReview = event.target.question.value;

    // Security checks
    var patterns = [
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, // XSS check
      /<[^>]+>/gi, // Basic HTML tags check
      /(;|--|\/\*|\*\/|@@|@|char|nchar|varchar|nvarchar|alter|begin|cast|create|cursor|declare|delete|drop|end|exec|execute|fetch|insert|kill|open|select|sys|sysobjects|syscolumns|table|update)/gi, // SQL injection check
    ];

    let isValid = true;
    for (var i = 0; i < patterns.length; i++) {
      if (patterns[i].test(review)) {
        isValid = false;
        break;
      }
    }
    if (!isValid) {
      userReview = "User submitted review that could cause an issue";
    }
    saveReview.mutate({
      date: currentTimestampMilliseconds,
      feedback: userReview,
    });
    setReview("");
    setIsReviewSubmitted(true);
  };

  return (
    <LayoutContainer>
      <form onSubmit={handleSubmit}>
        <h3>We'd love to hear your thoughts!</h3>
        <p>
          🔒 🌟 Your Voice, Your Privacy: We highly value your feedback as it is
          crucial for our growth and allows us to enhance your experience. We
          invite you to share your insights about the website. How do you feel
          about the ability to save all your answers? Have you had a chance to
          use the login and save feature? We're eager to hear which new features
          you'd love to see and what enhancements could make your visit even
          more fulfilling. Your feedback will be kept strictly confidential.
          Please, express your thoughts freely — every voice matters to us.
        </p>
        <textarea
          className="text-field"
          value={review}
          onChange={handleInputChange}
          placeholder="Your review..."
          rows="4"
          required
        />
        <button className="auth_button" type="submit">
          Submit Review
        </button>
      </form>
      {isReviewSubmitted && <div> Thank you for submitting review 💌 </div>}
      <ButtonGeneral onClick={() => navigate("/free-question-tarot")}>
        One Card Layout
      </ButtonGeneral>
      <ButtonGeneral onClick={() => navigate("/")}>Home Page</ButtonGeneral>
    </LayoutContainer>
  );
};

export default ReviewForm;
