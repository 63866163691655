import { generateId } from "../../utils/generateNumber";
import styles from "./styles.css";
import dayjs from "dayjs";
import { useMemo } from "react";

const QuestionCardsInfo = ({ cardToDisplay }) => {
  const date = dayjs(cardToDisplay.date);
  const dateToDisplay = useMemo(() => {
    return date.format("MMM D, YYYY");
  }, [date]);

  return (
    <div className="general_question_card_info">
      <br />
      <div className="question">
        {" "}
        <span style={{ marginRight: "10px", fontWeight: "bold" }}>
          Question: {cardToDisplay.question}
        </span>{" "}
        {}
      </div>
      <div>Date: {dateToDisplay}</div>

      <div className="question-card-info" css={styles}>
        <img
          className={
            cardToDisplay.isReversed[0]
              ? "card-image card-image-reversed"
              : "card-image"
          }
          src={cardToDisplay.cardsImg}
          alt="chosen card"
        />

        <div className="answer" style={{ margin: '2px', width: 'auto' }}>
          <br />
          Answers:{" "}
          {cardToDisplay.answers.map((answers) => (
            <div
              key={generateId()}
              dangerouslySetInnerHTML={{ __html: answers }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionCardsInfo;
