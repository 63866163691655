import React, { useMemo, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useGetGeneralCategories } from "../../../provider/GeneralQuestionsData";
import { generateId } from "../../../utils/generateNumber";
import QuestionContainer from "../QuestionContainer";
import LayoutContainer from "../../../containers/Layout";
import { newQuestionForm } from "../QuestionsUtils";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../config/FirebaseConfiguration";
import Button from "../../../components/Button";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import styles from "./styles.css";

function ChooseQuestions(props) {
  const { data: allCategoriesData } = useGetGeneralCategories();
  const queryClient = useQueryClient();
  queryClient.setQueryData(["allCategories"], allCategoriesData);
  const categoriesData = queryClient.getQueryData(["allCategories"]);
  const userData = useAuthContext();
  // const userData = queryClient.getQueryData(["userData"]);
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const [categoryNameToDisplay, setCategoryNameToDisplay] = useState("");

  const goOneCardSpreadPage = useCallback(
    (question) => {
      let newQuestion = newQuestionForm(userData, question);
      queryClient.setQueryData(["userQuestion"], newQuestion);
      navigate(`/free-question-tarot`);
      logEvent(analytics, "question_chosen", "link");
    },
    [userData, navigate, queryClient]
  );

  const goOneCardSpreadPageCallback = useCallback(
    (question) => {
      goOneCardSpreadPage(question);
    },
    [goOneCardSpreadPage]
  );

  const questionsToDisplay = useMemo(() => {
    return categoriesData.map((category) => {
      if (category.category_path === categoryName) {
        setCategoryNameToDisplay(category.category_name);
        return category.questions.map((question) => (
          <QuestionContainer
            key={generateId()}
            onClick={() => goOneCardSpreadPageCallback(question)}
            question={question}
          />
        ));
      }
      return null; // Return a default value in case the condition is not met
    });
  }, [categoriesData, categoryName, goOneCardSpreadPageCallback]);

  return (
    <LayoutContainer>
      <div className="choose_questions_container" css={styles}>
        <h1>Choose Question</h1>
        <h2>{categoryNameToDisplay}</h2>
        {questionsToDisplay}
      </div>
      <Button onClick={() => navigate("/choose-subject-to-ask-tarot")}>
        Choose Category
      </Button>
      <Button onClick={() => navigate("/")}>Home Page</Button>
    </LayoutContainer>
  );
}

export default ChooseQuestions;
