import { useEffect } from "react";
import LayoutContainer from "../../../containers/Layout";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ButtonGeneral from "../../../components/Button";
import { useCreateQuestion } from "../../../provider/QuestionsData";
import QuestionCardsInfo from "../../../containers/QuestionCardInfo";
import { useAuthContext } from "../../../provider/Login/AuthProvider";

export default function SavedCard() {
  const queryClient = useQueryClient();
  const userData = useAuthContext();
  const saveQuestion = useCreateQuestion();
  const userQuestion = queryClient.getQueryData(["userQuestion"]);
  const navigation = useNavigate();

  const saveCardFunction = () => {
    let userQuestionModified = userQuestion;

    if (userQuestion.userID === "no_user") {
      userQuestionModified.userID = userData.uid;
      queryClient.setQueryData(["userQuestion"], userQuestionModified);
    }

    saveQuestion.mutate(userQuestionModified);
  };

  useEffect(() => {
    if (userQuestion) saveCardFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userQuestion]);
  return (
    <LayoutContainer>
      <div className="guide">
        <h2>The answer for your question was saved!</h2>
        <p>You can find more of your saved answers in "My Diary Tarot "</p>
        <ButtonGeneral onClick={() => navigation("/my-tarot-diary")}>
          My Diary Tarot
        </ButtonGeneral>
        {userQuestion && <QuestionCardsInfo cardToDisplay={userQuestion} />}
      </div>
    </LayoutContainer>
  );
}
