import { AuthErrorCodes } from "firebase/auth";
import LayoutContainer from "../../../containers/Layout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendForgotPasswordEmail } from "../../../provider/Login/LoginEmailPassword";
import ButtonGeneral from "../../../components/Button";

export default function ForgotPassword() {
  const [error, setError] = useState("");
  const [guideText, setGuideText] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSendingEmail = async () => {
    if (email === "") {
      setError("Please fill in all fields");
    } else {
      try {
        await sendForgotPasswordEmail(email);
        setGuideText(
          "Go to your email. You will get the email from the the 'Diary Tarot '. Follow the link and reset your password"
        );
        setError("");
      } catch (error) {
        setGuideText("");
        if (error.message === "Firebase: Error (auth/user-not-found).") {
          setError("The email doesn't exists. Type a new email");
        } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
          setError("The email is invalid. Type a new email");
        } else if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
          setError("Wrong password! Try once again.");
        } else {
          setError(error.message);
        }
      }
    }
  };

  return (
    <LayoutContainer>
      <div className="guide_text_auth">
        <h1>Forgot Password</h1>
        <form>
          Type your email that you have used to registered to the Diary Tarot
          website.
          <br />
          {error && <p style={{ color: "red" }}>{error}</p>}
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <button
            type="button"
            className="auth_button"
            onClick={handleSendingEmail}
          >
            Send
          </button>
          {guideText && <p>{guideText}</p>}
        </form>
        <ButtonGeneral onClick={() => navigate("/login")}>
          Go to Login
        </ButtonGeneral>
      </div>
    </LayoutContainer>
  );
}
