/**
 * Converts a text with numbered sections into HTML with separate paragraphs.
 * @param {string} text - The input text containing numbered sections.
 * @returns {string} - The generated HTML string.
 */
export function generateHTMLFromText(text) {
  const htmlContent = text
    .split(/(?=\d+\.\s*)/)
    .map((section) => {
      // Identify and format headings and content
      return section
        .replace(/(\d+)\.\s*(.*?):/g, "<br><h2>$2:</h2>") // Format numbered headings
        .replace(/\n&([^&]+)&\n/g, "<h2>$1</h2>") // Format text between "\n&" and "&\n" as h2
        .replace(/###\s*(.*?):/g, "<br><h3>$1:</h3>") // Format subheadings
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Format bold text
        .replace(/\*(.*?)\*/g, "<em>$1</em>") // Format italic text
        .replace(/In summary,/g, "<br><br><u>In summary,</u>") // Other replacements
        .replace(/Overall,/g, "<br><br><u>Overall,</u>")
        .replace(/In conclusion,/g, "<br><br><u>In conclusion,</u>")
        .replace(/^\d+\.\s*/, "") // Remove leading numbers
        .replace(/(?:\r\n|\r|\n)/g, "<p>$&</p>"); // Convert newlines to paragraphs
    })
    .join("\n");
  return `<div>\n${htmlContent}\n</div>`;
}
